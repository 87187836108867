
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import { auth } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';

@Component({
    components: {
        SvgIcon,
    },
})
export default class ActiveSessionDetected extends Vue {

    // link : {name: string} = {name: ''}

    mounted(){
        this.$socket.client.disconnect();
        this.$socket.client.close();
    }

    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
     isAuthenticated!: boolean;
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    user
}
